<template>
  <div id="app">
    <div
      id="main_nav"
      class="navbar navbar-dark bg-primary shadow-sm navbar-expand-md"
    >
      <a class="navbar-brand" href="/">inCare</a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="/evaluations" id="link_dlist"
              >ML Dashboard</a
            >
          </li>
        </ul>
        <nav class="navbar-text">
          <!-- <a v-if="loggedIn" href="#">身份：{{ userName }}&nbsp;&nbsp;</a> -->
          <!--  <a v-if="loggedIn">&nbsp;&nbsp;|&nbsp;&nbsp;</a>

          <a v-if="isAdmin" href="#">
            <router-link :to="{ name: 'register' }">Create User</router-link>
          </a>
          <a v-if="isAdmin">&nbsp;&nbsp;|&nbsp;&nbsp;</a>

          <a v-if="isAdmin" href="#">
            <router-link :to="{ name: 'generate_token' }">Generate API Token</router-link>
          </a>
          <a v-if="isAdmin">&nbsp;&nbsp;|&nbsp;&nbsp;</a>-->

          <!-- <a v-if="loggedIn" @click="logout" href="#">登出</a>
          <a v-if="!loggedIn">
            <router-link :to="{ name: 'login' }">Login</router-link>
          </a>
          <a v-if="!loggedIn">&nbsp;&nbsp;</a> -->
        </nav>
      </div>
    </div>

    <router-view @displayUsername="updateUsername" />

    <footer class="copyright text-center">
      <small class="d-block mb-3 text-muted">inCare &copy; 2019</small>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userName: this.$store.state.username
    };
  },
  mounted() {
    console.log(process.env.VUE_APP_API_URL);
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    isAdmin() {
      return this.$store.state.role === "admin" && this.$store.getters.loggedIn;
    }
  },
  methods: {
    updateUsername(username) {
      this.userName = username;
    },
    logout() {
      this.$store.dispatch("destroyToken").then((o) => {
        this.$router.push({ name: "login" });
      });
    }
  }
};
</script>

<style>
body {
  background: #eee !important;
}

.wrapper {
  margin-top: 80px;
  margin-bottom: 80px;
}
.copyright {
  margin-top: 550px;
}
.navbar-dark .navbar-nav .nav-link {
  color: whitesmoke !important;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: white !important;
}
#nprogress .bar {
  background: #151515 !important;
}
</style>
