var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mt-4" },
    [
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex justify-content-between align-items-center"
          },
          [
            _c("p", { attrs: { id: "cardTitle" } }, [
              _vm._v(
                "Total of " + _vm._s(_vm.numberOfElements) + " Evaluations"
              )
            ]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model_name,
                    expression: "model_name"
                  }
                ],
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.model_name = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { attrs: { disabled: "", value: "" } }, [
                  _vm._v("Please select one model")
                ]),
                _vm._l(_vm.models, function(model) {
                  return _c("option", { domProps: { value: model } }, [
                    _vm._v(_vm._s(model))
                  ])
                })
              ],
              2
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: { click: _vm.requestDiagnoses }
              },
              [_vm._v("Get " + _vm._s(_vm.model_name) + " evaluations")]
            ),
            _c("p", { attrs: { id: "cardTitle" } }, [
              _vm._v(
                "\n        Sensitivity: " +
                  _vm._s(
                    (_vm.total_sensitivity / _vm.numberOfElements).toFixed(2)
                  ) +
                  "\n        ..::.. Specificity: " +
                  _vm._s(
                    (_vm.total_specificity / _vm.numberOfElements).toFixed(2)
                  ) +
                  "\n        ..::.. Accuracy: " +
                  _vm._s((_vm.hit / _vm.numberEvaluations).toFixed(2)) +
                  "\n      "
              )
            ])
          ]
        ),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "table-responsive" },
            [
              _c(
                "mdb-container",
                { ref: "mdbContainer" },
                [
                  _c("mdb-datatable", {
                    attrs: {
                      data: _vm.data,
                      striped: "",
                      bordered: "",
                      responsive: "",
                      tfoot: false,
                      previous: "Previous",
                      order: [1, "desc"]
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c("EvaluationDeleteModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }