<template>
  <div class="container mt-4">
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <p id="cardTitle">Total of {{ numberOfElements }} Evaluations</p>
        <select v-model="model_name">
          <option disabled value>Please select one model</option>
          <option v-for="model in models" v-bind:value="model">{{ model }}</option>
        </select>
        <button class="btn btn-primary" @click="requestDiagnoses">Get {{ model_name }} evaluations</button>
        <p id="cardTitle">
          Sensitivity: {{ (total_sensitivity / numberOfElements).toFixed(2) }}
          ..::.. Specificity: {{ (total_specificity / numberOfElements).toFixed(2) }}
          ..::.. Accuracy: {{ (hit / numberEvaluations).toFixed(2) }}
        </p>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <mdb-container ref="mdbContainer">
            <mdb-datatable
              :data="data"
              striped
              bordered
              responsive
              :tfoot="false"
              previous="Previous"
              :order="[1, 'desc']"
            />
          </mdb-container>
        </div>
      </div>
    </div>
    <EvaluationDeleteModal />
    <!-- <DiagnosisControlModal />-->
  </div>
</template>

<script>
import { mdbDatatable, mdbContainer } from "mdbvue";
// import moment from "moment";
import EvaluationDeleteModal from "@/components/EvaluationDeleteModal.vue";
import OkayModal from "@/components/OkayModal.vue";
import { mapState } from "vuex";
// import { deleteEvaluation } from "../assets/helper_functions";

/*
* 
TODO

Get evaluations by model names - 
Display model score over all
*
*/
export default {
  name: "EvaluationsList",
  components: {
    mdbDatatable,
    mdbContainer,
    EvaluationDeleteModal,
    OkayModal
  },
  data() {
    return {
      model_name: ""
    };
  },
  computed: {
    ...mapState([
      "rows",
      "columns",
      "numberOfElements",
      "models",
      "total_sensitivity",
      "total_specificity",
      "numberEvaluations",
      "hit"
    ]),
    data() {
      const {
        rows,
        columns,
        numberOfElements,
        models,
        total_sensitivity,
        total_specificity,
        hit,
        numberEvaluations
      } = this;
      return {
        rows,
        columns,
        numberOfElements,
        models,
        total_sensitivity,
        total_specificity,
        hit,
        numberEvaluations
      };
    }
  },
  methods: {
    requestDiagnoses() {
      if (this.model_name != "") {
        this.$store
          .dispatch("requestEvaluations", {
            model_name: this.model_name
          })
          .then(result => {
            if (result == true) {
              console.log("Evaluations loaded");
            }
          })
          .catch(err => {
            //alert("Wrong username or password");
            console.log(err);
          });
      } else {
        alert("Select a model");
      }
    }
  },
  beforeMount() {
    console.log(this.model_name);
    this.$store.dispatch("requestModelsName");
  }
};
</script>

<style>
.deleteButton:hover {
  cursor: pointer;
}
</style>
