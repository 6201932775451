import { render, staticRenderFns } from "./EvaluationsList.vue?vue&type=template&id=f4dfafaa&"
import script from "./EvaluationsList.vue?vue&type=script&lang=js&"
export * from "./EvaluationsList.vue?vue&type=script&lang=js&"
import style0 from "./EvaluationsList.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/amonbazongo/inCare/incare_ml_dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f4dfafaa')) {
      api.createRecord('f4dfafaa', component.options)
    } else {
      api.reload('f4dfafaa', component.options)
    }
    module.hot.accept("./EvaluationsList.vue?vue&type=template&id=f4dfafaa&", function () {
      api.rerender('f4dfafaa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/EvaluationsList.vue"
export default component.exports