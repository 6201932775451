export default {
  SAVE_USERNAME(state, username) {
    state.username = username;
  },
  DELETE_USERNAME(state) {
    state.username = null;
  },
  SAVE_TOKEN(state, token) {
    state.token = token;
  },
  DELETE_TOKEN(state) {
    state.token = null;
  },
  SAVE_ROLE(state, role) {
    state.role = role;
  },
  DELETE_ROLE(state) {
    state.role = null;
  },
  SAVE_ROWS(state, rows) {
    state.rows = rows;
  },
  DELETE_ROWS(state) {
    state.rows = null;
  },
  SAVE_ROWS_NUMBER(state, number) {
    state.numberOfElements = number;
  },
  SAVE_EVALUATIONS_COLUMNS(state, columns_array) {
    state.columns = columns_array;
  },
  DELETE_DIAGNOSIS(state) {
    state.diagnoses = [];
  },
  SAVE_MODELS(state, models) {
    state.models = models;
  },
  INSERT_EVALUATIONS(state, evaluations) {
    state.evaluations = evaluations;
  },
  DELETE_EVALUATION_BY_ID(state, evaluation_id) {
    for (var i = 0; i < state.rows.length; i++) {
      if (state.rows[i].id == evaluation_id.toString()) {
        state.rows.splice(i, 1);
        break;
      }
    }
  },
  UPDATE_NOTE(state, note) {
    for (var i = 0; i < state.notes.length; i++) {
      if (state.notes[i].id == note.id) {
        state.notes[i].note = note.note;
        break;
      }
    }
  },
  UPDATE_NUMBER_EVALUATION(state) {
    state.numberEvaluations = state.numberEvaluations + 1;
  },
  UPDATE_NUMBER_HIT(state) {
    state.hit = state.hit + 1;
  },
  INIT_HIT_EVALUATION(state) {
    state.hit = 0;
    state.numberEvaluations = 0;
  },
  UPDATE_SENSITIVITY(state, sensitivity) {
    state.total_sensitivity = state.total_sensitivity + sensitivity;
  },
  UPDATE_SPECITIVITY(state, specificity) {
    state.total_specificity = state.total_specificity + specificity;
  },
  INIT_SENSITIVITY_SPECIFICITY(state) {
    state.total_sensitivity = 0;
    state.total_specificity = 0;
  },
  SAVE_EVALUATION(state, evaluation) {
    state.evaluationTags = evaluation;
  },
  DELETE_EVALUATION(state) {
    state.evaluationTags = [];
  }
};
