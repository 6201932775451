var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade show",
      attrs: {
        id: "page-modal-diagnosis-confirm",
        "data-mode": "",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "ModalLabel",
        "aria-modal": "true",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c(
            "form",
            {
              attrs: { id: "modal_form" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.deleteEvaluation($event)
                }
              }
            },
            [
              _vm._m(0),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v("Do you really want to delete this evaluation?")
              ]),
              _vm._m(1)
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Please confirm")]),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-success", attrs: { type: "submit" } },
        [_vm._v("Yes")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_vm._v("No")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }