<template>
  <div class="container mt-4">
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <router-link :to="{ name: 'evaluations' }">
          <button type="button" class="btn btn-sm btn-secondary" title="Dashboard">
            <i class="fa fa-home"></i>
          </button>
        </router-link>
        <a target="_blank" :href="diagnosis_link">Check this diagnosis on Dashboard</a>
        <!-- <select v-model="model_name">
          <option disabled value>Please select one model</option>
        </select>-->
        <!-- <button class="btn btn-primary" @click="requestDiagnoses">Get {{ model_name }} evaluations</button> -->
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <mdb-container ref="mdbContainer">
            <mdb-datatable
              :data="data"
              striped
              bordered
              responsive
              :tfoot="false"
              previous="Previous"
              :order="[1, 'desc']"
            />
          </mdb-container>
        </div>
      </div>
    </div>
    <EvaluationDeleteModal />
    <!-- <DiagnosisControlModal />-->
  </div>
</template>

<script>
import { mdbDatatable, mdbContainer } from "mdbvue";
import EvaluationDeleteModal from "@/components/EvaluationDeleteModal.vue";
import OkayModal from "@/components/OkayModal.vue";
import { mapState } from "vuex";

export default {
  name: "EvaluationsDetails",
  components: {
    mdbDatatable,
    mdbContainer,
    EvaluationDeleteModal,
    OkayModal
  },
  data() {
    return {
      model_name: "",
      diagnosis_link:
        "https://incare-dev.intelliances.com/diagnosis/" +
        this.$route.params.diagnosis_id
    };
  },
  computed: {
    ...mapState(["rows", "columns"]),
    data() {
      const { rows, columns } = this;
      return {
        rows,
        columns
      };
    }
  },
  methods: {
    requestDiagnoses() {}
  },
  beforeMount() {
    this.$store
      .dispatch("requestEvaluationsByDiagnosis", {
        diagnosis_id: this.$route.params.diagnosis_id
      })
      .then(result => {
        if (result == true) {
          console.log("Evaluations by Diagnosis loaded");
        }
      })
      .catch(err => {
        //alert("Wrong username or password");
        console.log(err);
      });
  }
};
</script>

<style>
.deleteButton:hover {
  cursor: pointer;
}
</style>
