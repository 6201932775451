<template>
  <div
    id="page-modal-diagnosis-confirm"
    class="modal fade show"
    data-mode
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalLabel"
    aria-modal="true"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form id="modal_form" @submit.prevent="deleteEvaluation">
          <div class="modal-header">
            <h5 class="modal-title">Please confirm</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">Do you really want to delete this evaluation?</div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-success">Yes</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as apiUrl from "../api_url.global";

export default {
  name: "EvaluationDeleteModal",

  methods: {
    deleteEvaluation() {
      $("#page-modal-diagnosis-confirm").modal("hide");

      this.$store
        .dispatch("deleteEvaluation", {
          evaluation_id: localStorage.getItem("evaluation_to_delete")
        })
        .then(id => {
          alert("Evaluation deleted");
          location.reload();
          //console.log(diagnosis_id, new_numberOfElements, new_rows);
        });
    }
    // filterData(dataArr1, dataArr2) {
    //   let transformed_data = dataArr1.map(e => {
    //     return {
    //       patient_id:
    //         '<a href="/diagnosis/' +
    //         e.diagnosis_id +
    //         '">' +
    //         e.sn_serial_no +
    //         "</a>",
    //       datetime: moment(e.create_at).format("YYYY/MM/DD , HH:mm:ss"),
    //       measure_type: e.measure_type,
    //       measure_person: e.measure_person,
    //       tagged: dataArr2[e.diagnosis_id]
    //         ? dataArr2[e.diagnosis_id]
    //             .split('"')
    //             .slice(1, -1)
    //             .filter(o => {
    //               return o != "][";
    //             })
    //         : "尚未備註",
    //       delete:
    //         '<i class="deleteDiagnosisButton fa fa-trash" onclick=\'deleteDiagnosis("' +
    //         e.diagnosis_id +
    //         "\")'></i>"
    //     };
    //   });
    //   return transformed_data;
    // },
    // transformNotes(notes) {
    //   var notes_to_return = [];
    //   var notes_tags = [];
    //   notes.forEach(e => {
    //     notes_to_return[e.diagnosis_id] = e.created_at;
    //     notes_tags[e.diagnosis_id] += [e.note];
    //   });
    //   return notes_tags;
    // }
  }
};
</script>

<style></style>
