import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import mutations from "./store/mutations";
import actions from "./store/actions";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "vuex",
  storage: window.localStorage
});

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: {
    token: null,
    username: "",
    role: localStorage.getItem("role") || "",
    // evaluations: null,
    rows: [],
    columns: [],
    numberOfElements: 0,
    models: [],
    total_sensitivity: 0,
    total_specificity: 0,
    hit: 0,
    numberEvaluations: 0
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    }
    // getEvaluations(state) {
    //   return state.evaluations;
    // }
  },
  mutations,
  actions
});
