import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
//import Login from "./views/Login.vue";
import EvaluationsList from "./views/EvaluationsList.vue";
import EvaluationsDetails from "./views/EvaluationsDetails.vue";
// import Register from "./views/Register.vue";
// import GenerateToken from "./views/GenerateToken.vue";
import store from "./store.js";

Vue.use(Router);

const routes = [
  // {
  //   path: "/login",
  //   name: "login",
  //   component: Login,
  //   meta: {
  //     requiresVisitor: true
  //   }
  // },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: Register,
  //   meta: {
  //     requiresAuth: true,
  //     is_admin: true
  //   }
  // },
  // {
  //   path: "/generate_token",
  //   name: "generate_token",
  //   component: GenerateToken,
  //   meta: {
  //     requiresAuth: true,
  //     is_admin: true
  //   }
  // },
  {
    path: "/evaluations",
    name: "evaluations",
    component: EvaluationsList,
    meta: {
      requiresAuth: true,
      is_regular: false
    }
  },
  {
    path: "/evaluation/:diagnosis_id",
    name: "evaluations_details",
    component: EvaluationsDetails
    // meta: {
    //   requiresAuth: true
    // },
    // beforeEnter(routeTo, routeFrom, next) {
    //   store.dispatch("deleteAnomalyData").then(() => {
    //     next();
    //   });
    // }
  },
  {
    path: "/",
    name: "home",
    component: Home
  }
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (!store.getters.loggedIn) {
//       next({
//         name: "login"
//       });
//     } else {
//       next();
//     }
//   }
//   if (to.matched.some((record) => record.meta.requiresVisitor)) {
//     if (store.getters.loggedIn) {
//       next({
//         name: "evaluations"
//       });
//     } else {
//       next();
//     }
//   }

//   if (to.meta.is_admin) {
//     if (store.state.role !== "admin") {
//       next({
//         name: "login"
//       });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }

//   if (!to.meta.is_regular) {
//     if (store.state.role === "regular") {
//       next({
//         name: "home"
//       });
//     } else {
//       next();
//     }
//   }

//   next();
// });

export default router;
