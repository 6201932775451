var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mt-4" },
    [
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex justify-content-between align-items-center"
          },
          [
            _c("router-link", { attrs: { to: { name: "evaluations" } } }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-secondary",
                  attrs: { type: "button", title: "Dashboard" }
                },
                [_c("i", { staticClass: "fa fa-home" })]
              )
            ]),
            _c("a", { attrs: { target: "_blank", href: _vm.diagnosis_link } }, [
              _vm._v("Check this diagnosis on Dashboard")
            ])
          ],
          1
        ),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "table-responsive" },
            [
              _c(
                "mdb-container",
                { ref: "mdbContainer" },
                [
                  _c("mdb-datatable", {
                    attrs: {
                      data: _vm.data,
                      striped: "",
                      bordered: "",
                      responsive: "",
                      tfoot: false,
                      previous: "Previous",
                      order: [1, "desc"]
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c("EvaluationDeleteModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }