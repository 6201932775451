var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm._m(0),
      _c("router-view", { on: { displayUsername: _vm.updateUsername } }),
      _vm._m(1)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "navbar navbar-dark bg-primary shadow-sm navbar-expand-md",
        attrs: { id: "main_nav" }
      },
      [
        _c("a", { staticClass: "navbar-brand", attrs: { href: "/" } }, [
          _vm._v("inCare")
        ]),
        _c(
          "button",
          {
            staticClass: "navbar-toggler",
            attrs: {
              type: "button",
              "data-toggle": "collapse",
              "data-target": "#navbarText",
              "aria-controls": "navbarText",
              "aria-expanded": "false",
              "aria-label": "Toggle navigation"
            }
          },
          [_c("span", { staticClass: "navbar-toggler-icon" })]
        ),
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse",
            attrs: { id: "navbarText" }
          },
          [
            _c("ul", { staticClass: "navbar-nav mr-auto" }, [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: { href: "/evaluations", id: "link_dlist" }
                  },
                  [_vm._v("ML Dashboard")]
                )
              ])
            ]),
            _c("nav", { staticClass: "navbar-text" })
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "copyright text-center" }, [
      _c("small", { staticClass: "d-block mb-3 text-muted" }, [
        _vm._v("inCare © 2019")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }