import * as apiUrl from "../api_url.global";
import axios from "axios";
import moment from "moment";

export default {
  setUpRows(context, data) {
    context.commit("SAVE_ROWS", data.rows);
    // context.commit("SAVE_ROWS_NUMBER", data.numberOfElements);
    // context.commit("SAVE_UPDATE_TIME", data.diagnosesUpdateTime);
  },
  requestEvaluationsByDiagnosis({ commit, state }, data) {
    const config = {
      url: apiUrl.url + "/medical/v2/evaluation/" + data.diagnosis_id,
      //headers: { "Content-Type": "application/json" },
      method: "get"
    };

    return axios(config)
      .then((res) => {
        var eval_data = res.data.data;
        let transformed_data = eval_data.map((e) => {
          return {
            datetime: moment(e.last_updated).format("YYYY/MM/DD , HH:mm:ss"),
            channel: e.channel,
            evaluator: e.evaluator,
            model: e.model_name,
            evaluation: e.evaluation,
            delete:
              '<i class="deleteButton fa fa-trash" onclick=\'deleteEvaluation("' +
              e.id +
              '", "' +
              "\")'></i>"
          };
        });
        //console.log(transformed_data);

        var columns = [
          {
            label: "Datetime",
            field: "datetime"
          },
          {
            label: "Channel",
            field: "channel"
          },
          {
            label: "Evaluator",
            field: "evaluator"
          },
          {
            label: "Model",
            field: "model"
          },
          {
            label: "Evaluation",
            field: "evaluation"
          },
          {
            label: "",
            field: "delete"
          }
        ];

        commit("SAVE_EVALUATIONS_COLUMNS", columns);
        commit("SAVE_ROWS", transformed_data);
      })
      .catch((err) => {
        console.log(err);
        return Promise.resolve(false);
      });
  },
  requestModelsName({ commit, state }) {
    // Get models name and save
    axios
      .get("https://dev.intelliances.com/broker/medical/v2/models/name")
      .then((res) => {
        var models = [];
        models.push(res.data["default"]);
        for (var v in res.data["challengers"]) {
          models.push(res.data["challengers"][v]);
        }
        commit("SAVE_MODELS", models);
      });
  },
  requestEvaluations({ commit, state }, model_name) {
    // console.log(model_name);
    const config = {
      url: apiUrl.url + "/medical/v2/evaluation/model/" + model_name.model_name,
      //headers: { "Content-Type": "application/json" },
      method: "get"
    };
    commit("INIT_SENSITIVITY_SPECIFICITY");
    commit("INIT_HIT_EVALUATION");

    return axios(config)
      .then((res) => {
        const eval_data = res.data.data;
        var transformed_data = [];
        //console.log(eval_data);

        for (var i in eval_data) {
          var id_split = i.split(":");

          // Positive means there an anomaly
          // Negative means there is no anomaly
          var true_positive = 0;
          var true_negative = 0;
          var false_negative = 0;
          var false_positive = 0;
          var specificity = 0;
          var sensitivity = 0;
          var ai_sequence = [];
          var total_points = 0;
          eval_data[i].forEach((e) => {
            ai_sequence = JSON.parse(e.ai_sequence[0]);
            total_points = total_points + 250;
            //console.log(ai_sequence[id_split[1]].length);

            // 0: actual; 1: predicted
            var actual_predicted = e.evaluation.split(":");
            commit("UPDATE_NUMBER_EVALUATION");
            // if (actual_predicted[0] == actual_predicted[1]) {
            // commit("UPDATE_NUMBER_HIT");
            // }
            if (
              (actual_predicted[0] != "Normal Sinus Rhythm") &
              (actual_predicted[1] != "Normal Sinus Rhythm")
            ) {
              true_positive = true_positive + 1;
              commit("UPDATE_NUMBER_HIT");
            }
            if (
              (actual_predicted[0] != "Normal Sinus Rhythm") &
              (actual_predicted[1] == "Normal Sinus Rhythm")
            ) {
              false_negative = false_negative + 1;
            }
            if (
              (actual_predicted[0] == "Normal Sinus Rhythm") &
              (actual_predicted[1] != "Normal Sinus Rhythm")
            ) {
              false_positive = false_positive + 1;
            }
          });
          // true_negative = (75000 - total_points) / 250;
          true_negative = ai_sequence[id_split[1]].length - false_negative;
          if (true_negative < 0) {
            true_negative = false_negative + true_negative;
          }

          // console.log(true_negative);
          // console.log(
          //   true_positive,
          //   true_negative,
          //   false_negative,
          //   false_positive
          // );

          specificity =
            true_negative / parseFloat(true_negative + false_positive);
          sensitivity =
            true_positive / parseFloat(true_positive + false_negative);

          if (sensitivity) {
            commit("UPDATE_SENSITIVITY", sensitivity);
          }
          if (specificity) {
            commit("UPDATE_SPECITIVITY", specificity);
          }

          transformed_data.push({
            diagnosis_id:
              '<a href="/evaluation/' +
              id_split[0] +
              '">' +
              id_split[0] +
              "</a>",
            channel: id_split[1],
            evaluator: eval_data[i][0].evaluator,
            true_positive: true_positive,
            true_negative: true_negative,
            false_negative: false_negative,
            false_positive: false_positive
            // delete:
            //   '<i class="deleteButton fa fa-trash" onclick=\'deleteEvaluation("' +
            //   eval_data[i][0].id +
            //   '", "' +
            //   "\")'></i>"
          });
        }
        // Sensitivity special case
        /*
          It can happen that sensitivity is NaN because true positive and false negative are both null.
          This can happen since sensitivity is about positive cases: true positive and false negative being null means that there is no positive case 
          even if it's predicted by AI. 
        */

        // Specificity special case
        /*
          It can happen that specificity is NaN because true negative and false positive are both null.
          This can happen since specificity is about negative cases: true negative and false positive being null means that all are negative case
        */

        var columns = [
          {
            label: "Diagnosis ID",
            field: "diagnosis_id"
          },
          {
            label: "Channel",
            field: "channel"
          },
          {
            label: "Evaluator",
            field: "evaluator"
          },
          {
            label: "True Positive",
            field: "true_positive"
          },
          {
            label: "True Negative",
            field: "true_negative"
          },
          {
            label: "False Negative",
            field: "false_negative"
          },
          {
            label: "False Positive",
            field: "false_positive"
          }
        ];

        commit("SAVE_EVALUATIONS_COLUMNS", columns);
        commit("SAVE_ROWS_NUMBER", transformed_data.length);
        commit("SAVE_ROWS", transformed_data);

        return Promise.resolve(true);
      })
      .catch((err) => {
        console.log(err);
        return Promise.resolve(false);
      });
  },
  deleteEvaluation(context, data) {
    const config = {
      url: apiUrl.url + "/medical/v2/evaluation/" + data.evaluation_id,
      headers: {
        //Authorization: "Bearer " + context.state.token,
        "Content-Type": "application/json"
      },
      method: "delete"
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then((resp) => {
          //console.log(resp.data);
          context.commit("DELETE_EVALUATION_BY_ID", data.evaluation_id);

          resolve(data.evaluation_id);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
};
